import { Avatar, Button, Image, Tag } from "@chakra-ui/react";
import { Notification as NotificationType } from "../../utils/@types";
import { MdOutlineClearAll } from "react-icons/md";
import { GoBell } from "react-icons/go";
import { formatUnderScoreText } from "../../utils";
import { useAuth } from "../../utils/hooks/user.hook";
import { useAxios } from "../../utils/axios.config";

interface NotificationProps extends NotificationType {}

const Notification = ({
  icon,
  title,
  time,
  description,
  badge,
  status,
}: NotificationProps) => {
  return (
    <div
      className={[
        "flex flex-row items-start gap-2 rounded-md py-4 px-2",
        status ? "bg-white" : "bg-blue-100",
      ].join(" ")}
    >
      <Avatar icon={<GoBell />} className="h-10 w-10 rounded-full object-cover"/>
      <div className="flex flex-col gap-2">
        <div className="flex flex-row items-center gap-2">
          <span className="text-lg">{title}</span>
          {badge && (
            <Tag rounded={'full'} px={2} variant="solid" colorScheme="blue" color={'white'}>
              {formatUnderScoreText(badge)}
            </Tag>
          )}
          <span className="text-sm text-color-a2">{time}</span>
        </div>
        <p className="text-color-a2 text-sm">{description}</p>
      </div>
    </div>
  );
};

interface NotificationPanelProps {
  notifications: NotificationType[];
  markAllAsRead: () => void;
  clearAll: () => void;
}

const NotificationPanel = ({ notifications, markAllAsRead, clearAll }: NotificationPanelProps) => {
  return (
    <div className="p-4 bg-white rounded-lg w-full">
      <section className="flex flex-row justify-between items-center gap-4 pb-2 border-b border-b-gray-200">
        <h1 className="font-bold text-xl">Notifications</h1>
        <Button className="text-dark-blue text-sm cursor-pointer" rounded={'full'} variant={'ghost'} onClick={markAllAsRead}>
          &#10003; Mark All As Read
        </Button>
      </section>
      <div className="flex flex-col gap-2 mt-4">
        {notifications.map((notification, index) => (
          <Notification key={index} {...notification} />
        ))}
      </div>
      <hr className="w-full bg-gray-200 my-4"/>
      <Button rounded={'full'} leftIcon={<MdOutlineClearAll />} p={6} onClick={clearAll}>
        Clear All
      </Button>
    </div>
  );
};

export default NotificationPanel;
