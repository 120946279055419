import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Textarea, useDisclosure, useToast } from "@chakra-ui/react";
import { CiLogout } from "react-icons/ci";
import { FiMonitor } from "react-icons/fi";
import { Link } from "react-router-dom";
import { SideBarItems } from "../../utils/@types";
import { useState } from "react";
import { useAxios } from "../../utils/axios.config";
import { useAuth } from "../../utils/hooks/user.hook";

const SideBar = ({ items }: { items: SideBarItems }) => {
  const { top, bottom, current = "" } = items;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [supportIssue, setSupportIssue] = useState("");
  const toast = useToast();
  const { axios } = useAxios();
  const { user } = useAuth();

  const handleSupportSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(!supportIssue) {
      return toast({
        title: "Empty field",
        description: "Please describe the issue that you would like addressed"
      })
    }

    const submissionPromise = new Promise((res, rej) => {
      axios
        .post("/admin/support", {
          email: user.email,
          message: supportIssue
        })
        .then(() => {
          res("DONE");
        })
        .catch((_e: any) => {
          console.log("[support] failed");
          rej("ERROR");
        }).finally(() => {
          onClose();
        });
    });
    toast.promise(submissionPromise, {
      loading: {
        title: "Submitting issue...",
      },
      success: {
        title: "Issue submitted",
        duration: 400,
      },
      error: {
        title: "Something Went Wrong.",
        description: "Please try again later",
      },
    });
  }

  return (
    <div className="sticky top-0">
      <nav className="w-[16rem] p-5 h-screen hidden lg:flex flex-col justify-between bg-white">
        <div className="">
          <Link to="/" className="">
            <img src={'/logo_white_bg.jpg'} alt="" className="h-20"/>
          </Link>

          <div className="mt-12">
            {top.map((item, index) => (
              <Link key={index} to={item.link}>
                <Button
                  leftIcon={<item.icon className="text-xl" />}
                  variant="ghost"
                  colorScheme={
                    item.title.toLowerCase() === current.toLowerCase()
                      ? "blue"
                      : "gray"
                  }
                  className="w-full"
                  justifyContent="flex-start"
                  _hover={{
                    color: "blue.500",
                    borderLeft: "3px solid #2563EB",
                  }}
                  bgColor={
                    item.title.toLowerCase() === current.toLowerCase()
                      ? "blue.50"
                      : ""
                  }
                  borderLeft={
                    item.title.toLowerCase() === current.toLowerCase()
                      ? "3px solid #2563EB"
                      : "3px solid white"
                  }
                  fontWeight={500}
                  fontSize={"sm"}
                  gap={2}
                  py={7}
                  borderRadius={"0px"}
                >
                  {item.title}
                </Button>
              </Link>
            ))}
          </div>
        </div>

        <div className="mt-12 flex flex-col">
          <div className="flex flex-col gap-[10px]">
            {bottom.map((item, index) => (
              <Link key={index} to={item.link}>
                <Button
                  leftIcon={<item.icon className="text-xl" />}
                  variant="ghost"
                  colorScheme="gray"
                  className="w-full"
                  bgColor={
                    item.title.toLowerCase() === current.toLowerCase()
                      ? "blue.100"
                      : ""
                  }
                  justifyContent="flex-start"
                  borderRadius={"0px"}
                  borderLeft={
                    item.title.toLowerCase() === current.toLowerCase()
                      ? "3px solid #2563EB"
                      : "3px solid transparent"
                  }
                  _hover={{
                    color: "blue.500",
                    bg: "blue.50",
                    borderLeft: "3px solid #2563EB",
                  }}
                  fontWeight={500}
                  fontSize={"sm"}
                  gap={2}
                  py={7}
                >
                  {item.title}
                </Button>
              </Link>
            ))}

            <Button
              leftIcon={<FiMonitor className="text-xl" />}
              variant="ghost"
              colorScheme="gray"
              className="w-full"
              justifyContent="flex-start"
              borderRadius={"0px"}
              borderLeft={"3px solid transparent"}
              _hover={{
                color: "blue.500",
                bg: "blue.50",
                borderLeft: "3px solid #2563EB",
              }}
              fontWeight={500}
              fontSize={"sm"}
              gap={2}
              py={7}
              onClick={onOpen}
            >
              System support
            </Button>
            <Button
              leftIcon={<CiLogout className="text-xl" />}
              variant="ghost"
              colorScheme="red"
              className="w-full"
              justifyContent="flex-start"
              borderRadius={"0px"}
              borderLeft={"3px solid transparent"}
              _hover={{ bg: "red.50", borderLeft: "3px solid red" }}
              fontWeight={500}
              fontSize={"sm"}
              gap={2}
              py={7}
            >
              Logout
            </Button>
          </div>
        </div>
      </nav>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent bg="white" m="auto">
          <ModalHeader>System support</ModalHeader>
          <ModalCloseButton />
          <ModalBody paddingBottom={6}>
            <form onSubmit={handleSupportSubmit}>
              <Textarea
                placeholder="Describe the issues you are facing and get help !"
                required
                value={supportIssue}
                onChange={(e) => setSupportIssue(e.target.value)}
              />
              <Button size="lg" colorScheme="blue" mt={6} type="submit">
                Submit
              </Button>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default SideBar;
