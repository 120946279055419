import {
  HiOutlineDocumentText,
  HiOutlinePresentationChartLine,
} from "react-icons/hi";
import { SideBarItems } from "../../../utils/@types";
import { LuUsers } from "react-icons/lu";
import { LiaMoneyBillWaveSolid } from "react-icons/lia";
import { FaRegCommentAlt } from "react-icons/fa";
import { IoTimeOutline } from "react-icons/io5";
import { AiOutlineDollar } from "react-icons/ai";

export const SuperAdmin_SidebarItems: SideBarItems = {
  top: [
    {
      title: "Analytics",
      icon: HiOutlinePresentationChartLine,
      link: "/superadmin/analytics",
    },
    {
      title: "Users",
      icon: LuUsers,
      link: "/superadmin/users",
    },
    {
      title: "Writings",
      icon: HiOutlineDocumentText,
      link: "/superadmin/writings",
    },
    
    {
      title: "Plans",
      icon: LiaMoneyBillWaveSolid,
      link: "/superadmin/plans",
    },
    {
      title: "Payments",
      icon: AiOutlineDollar,
      link: "/superadmin/payments",
    },
  ],
  bottom: [
    // {
    //   title: "Feedback",
    //   icon: FaRegCommentAlt,
    //   link: "/superadmin/feedback",
    // },
    {
      title: "Logs",
      icon: IoTimeOutline,
      link: "/superadmin/logs",
    },
  ],
};
