import { ChakraProvider } from "@chakra-ui/react";
import { MantineProvider } from "@mantine/core";
import "./index.css";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import ReactDOM from "react-dom/client";
import theme from "./utils/chakra/theme.ts";
import App from "./App.tsx";
// import 'flowbite'

ReactDOM.createRoot(document.getElementById("root")!).render(
  // <React.StrictMode>
  <MantineProvider>
    <ChakraProvider
      theme={theme}
      toastOptions={{
        defaultOptions: {
          position: "top-right",
          containerStyle: {
            color: 'white',
          }
        },
        
      }}
    >
      <App />
    </ChakraProvider>
  </MantineProvider>
  // {/* </React.StrictMode> */}
);
