export const useAuth = () => {
  try {
    return {
      user: JSON.parse(localStorage.getItem("user") || "null"),
    };
  } catch (error) {
    return {
      user: null,
    };
  }
};
