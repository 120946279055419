import {
  Avatar, Button, Menu,
  MenuButton, MenuItem, MenuList, Popover, PopoverContent, PopoverTrigger
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaAngleDown } from "react-icons/fa6";
import { GoBell } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { notifications as dummyNotifications } from "../../utils/@data";
import { Notification } from "../../utils/@types";
import { useAuth } from "../../utils/hooks/user.hook";
import NotificationPanel from "./NotificationPanel";
import { useAxios } from "../../utils/axios.config";
import { IoIosNotifications } from "react-icons/io";
export interface Header1Props {
  title: string;
  subtitle: string;
  middle_elements?: JSX.Element | string | (JSX.Element | string)[];
}

const Header1 = ({ title, subtitle, middle_elements }: Header1Props) => {
  const navigate = useNavigate();

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const { user } = useAuth();
  const { axios } = useAxios();

  const markAllAsRead = () => {
    axios.post('/normal-user/notifications/mark-all-as-read').then((res) => {
      setNotifications(res.data)
    });
  };

  const clearAll = () => {
    axios.delete('/normal-user/notifications/clear-all').then((res) => {
      setNotifications(res.data)
    });
  }

  const fetchNotifications = () => {
    axios.get('/normal-user/notifications').then((res) => {
      setNotifications(res.data);
      console.log(res.data)
    });
  };

  useEffect(() => {
    // fetch data
    // setNotifications(dummyNotifications);
    fetchNotifications();

    if (!user) {
      navigate('/auth/login');
    }
  }, []);
  return (
    <header className="w-full flex items-center justify-between px-4 py-6 sticky top-0 bg-foreground z-20">
      <div>
        <h2 className="text-neutral-800 text-lg md:text-2xl font-semibold">{title}</h2>
        <p className="text-[#505050] text-sm md:text-md hidden xs:block">{subtitle}</p>
      </div>
      {middle_elements}
      <div className="flex items-center gap-3">
        <Popover placement="bottom-end">
          <PopoverTrigger>
            <div className="w-[40px] h-[40px] flex items-center bg-white rounded-full justify-center cursor-pointer">
              {notifications.some((notification) => !notification.status) ? (
                <IoIosNotifications className="text-black text-lg" />
              ) : (
                <GoBell className="text-black text-lg" />
              )}
            </div>
          </PopoverTrigger>
          <PopoverContent className="w-screen max-w-md" w={'md'}>
            <NotificationPanel markAllAsRead={markAllAsRead} clearAll={clearAll} notifications={notifications} />
          </PopoverContent>
        </Popover>
        <Menu>
          <MenuButton padding={6} bgColor={'gray.100'} as={Button} rightIcon={<FaAngleDown />}>
            <div className="flex items-center gap-2">
              <div>
                <Avatar size={'sm'} name={`${user.lastName} ${user.firstName}`} />
              </div>
              <div className="flex items-center gap-3">
                <div className="md:block hidden">
                  <p className="text-black font-medium">{user.lastName}</p>
                  <p className="text-[#50606F] text-xs">{user.firstName}</p>
                </div>
              </div>
            </div>
          </MenuButton>
          <MenuList>
            <MenuItem color={'red'} onClick={() => navigate('/auth/logout')}>
              Logout
            </MenuItem>
          </MenuList>
        </Menu>
      </div>
    </header>
  );
};

export default Header1;
