// import cx from 'clsx';
import { Checkbox, Pagination, Table, rem } from '@mantine/core';
import SortableTH from './SortableTH';

export interface RowData {
   [key: string]: string;
}

export interface RowContext {
   selected: boolean;
   value: string;
   row: RowData;
   rows: RowData[];
}

export interface Column {
   title: string;
   key: string;
   sortable?: boolean;
   align?: 'left' | 'center' | 'right';
   Element?: (rowContext: RowContext, refresh: () => void) => React.ReactNode;
}

interface CustomTableProps {
   className?: string;
   columns: Column[];
   data: RowData[];
   selection: string[];
   setSelection: React.Dispatch<React.SetStateAction<string[]>>;
   sortedData: RowData[];
   sortBy: keyof RowData | null;
   reverseSortDirection: boolean;
   onSort: (sortBy: keyof RowData) => void;
   pageSize?: number;
   page?: number;
   setPage?: React.Dispatch<React.SetStateAction<number>>;
   refresh: () => void;
}

export function CustomTable({
   columns,
   data,
   selection,
   setSelection,
   sortedData,
   sortBy,
   reverseSortDirection,
   onSort,
   pageSize = 10,
   page = 1,
   setPage = () => {},
   refresh,
}: CustomTableProps) {
   const toggleRow = (id: string) =>
      setSelection((current) => (current.includes(id) ? current.filter((item) => item !== id) : [...current, id]));
   const toggleAll = () => setSelection((current) => (current.length === data.length ? [] : data.map((item) => item.id)));

   const rows = sortedData
      .slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize)
      .map((item: RowData, item_index: number) => {
         return (
            <Table.Tr role="row" key={item_index} className={''}>
               <Table.Td>
                  <Checkbox checked={selection.includes(item.id)} onChange={() => toggleRow(item.id)} />
               </Table.Td>
               {columns.map((column, i: number) => {
                  return (
                     <Table.Td data-test-id={item[column.key]} key={i} align={column.align}>
                        {column.Element
                           ? column.Element({
                                selected: selection.includes(item.id),
                                row: item,
                                rows: data,
                                value: item[column.key],
                             }, refresh)
                           : item[column.key]}
                     </Table.Td>
                  );
               })}
            </Table.Tr>
         );
      });

   return (
      <div className='max-w-full overflow-x-auto'>
         <Table miw={800} verticalSpacing="sm">
            <Table.Thead>
               <Table.Tr>
                  <Table.Th style={{ width: rem(40) }}>
                     <Checkbox
                        onChange={toggleAll}
                        checked={selection.length === data.length}
                        indeterminate={selection.length > 0 && selection.length !== data.length}
                     />
                  </Table.Th>
                  {columns.map((column, i: number) => {
                     return (
                        <SortableTH
                           key={i}
                           {...(column.sortable && {
                              onSort: onSort.bind(null, column.key),
                              sorted: sortBy === column.key,
                              reversed: reverseSortDirection,
                           })}
                        >
                           {column.title}
                        </SortableTH>
                     );
                  })}
               </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{rows}</Table.Tbody>
         </Table>
         <Pagination total={Math.ceil(sortedData.length / pageSize)} value={page} onChange={setPage} color="gray" />
      </div>
   );
}
