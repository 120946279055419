import { Tabs } from '@mantine/core';
import styled from '@emotion/styled';

const StyledTabs = styled(Tabs)`
   & {
      padding: 0 6px;
      height: 100%;
   }
   & .mantine-Tabs-tab[data-active] {
      border: none;
      color: var(--__tab-border-color);
   }
   & .mantine-Tabs-tab[data-active]:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 80%;
      height: 2px;
      border-radius: 1px;
      background-color: var(--__tab-border-color);
      transform: translateX(-50%);
   }

   & ::before {
      display: none;
   }

   & .mantine-Tabs-list {
      height: 100%;
   }
`;

export default StyledTabs;
