import { useContext, useEffect, useState } from 'react';
import { LanguagesContext } from '../providers/language.provider';
import langs from '../../utils/@data/translations.json'
import { ISupportedLanguages } from '../../utils/@types';
import { el } from '@faker-js/faker';

interface TranslateProps {
    text: string;
    // dl: ISupportedLanguages
}

export function _translate(text: string) {
  const notFound = { rw: text, en: text, fr: text };
  
  const _word = langs.translations.find((el) => {
    const rw = el.rw.toLowerCase().trim();
    const en = el.en.toLowerCase().trim();
    const fr = el.fr.toLowerCase().trim();
    return [rw, en, fr].includes(text.toLowerCase().trim());
  });

  return _word ?? notFound;
}

export function useTranslate() {
  const context = useContext(LanguagesContext);
  const { language } = context!;

  const main = (text: string) => {
    let result = { rw: text, en: text, fr: text };

    result = _translate(text);
    const { rw, en, fr } = result;
    return ({ rw, en, fr }[language] ?? text) as string;
  };

  return main;
}

const Translate = ({text}: TranslateProps) => {

    const context = useContext(LanguagesContext)
    const {language} = context!

    const [rw, setRw] = useState<string>(text)
    const [en, setEn] = useState<string>(text)
    const [fr, setFr] = useState<string>(text)

    const main = ()=>{
        const _word = _translate(text)

        if(_word){
            setRw(_word.rw)
            setEn(_word.en)
            setFr(_word.fr)
        }
    }

    useEffect(()=>{
        main()
    }, [])
    
  return (
   <>
    {({ rw, en, fr }[language] ?? text) as string}
   </>
  )
}

export default Translate