import { extendTheme } from "@chakra-ui/react";

export default extendTheme({
  colors: {
    primary: {
      "25": "#F1F5F9",
      "50": "#eff4ff",
      "100": "#dbe6fe",
      "200": "#bfd3fe",
      "300": "#93b4fd",
      "400": "#6090fa",
      "500": "#3b76f6",
      "600": "#2563eb",
      "700": "#1d58d8",
      "800": "#1e4baf",
      "900": "#1e408a",
      "950": "#172a54",
      DEFAULT: "#2563eb",
    },
    accent: {
      "100": "#FED7AA",
      DEFAULT: "#FED7AA",
    },
    white: {
      "25": "#FFFFFF",
      "50": "#FFFFFF",
      "100": "#FFFFFF",
      "200": "#FFFFFF",
      "300": "#FFFFFF",
      "400": "#FFFFFF",
      "500": "#FFFFFF",
      "600": "#FFFFFF",
      "700": "#FFFFFF",
      "800": "#FFFFFF",
      "900": "#FFFFFF",
      "950": "#FFFFFF",
      DEFAULT: "#FFFFFF",
    }
  },
  breakpoints: {
    "2xs": "480px",
    xs: "560px",
    sm: "640px",
    md: "768px",
    lg: "1024px",
    xl: "1280px",
    "2xl": "1536px",
  },
});
