import SideBar from "../common/SideBar";
import Header1, { Header1Props } from "../common/Header1";
import { SideBarItems } from "../../utils/@types";

interface Props {
  children: JSX.Element | string | (JSX.Element | string)[];
  header: Header1Props;
  sidebar_items: SideBarItems;
}

const LoggedInShell = ({ children, header, sidebar_items }: Props) => {
  return (
    <main className="overflow-y-hidden h-screen w-screen bg-foreground flex flex-row">
      <SideBar
        items={sidebar_items}
      />
      {/* MAIN CONTENT */}
      <section className="grow overflow-y-auto">
        <Header1 {...header} />
        <div className="mt-0 px-4 py-2">{children}</div>
      </section>
    </main>
  );
};

export default LoggedInShell;
