import { TableContainer,Table, Thead, Tr, Th, Tbody, Td, Tfoot, Button } from "@chakra-ui/react"
import HeaderMiddleElements from "../../../components/common/HeaderMiddleElements"
import LoggedInShell from "../../../components/layout/LoggedInShell"
import { SuperAdmin_SidebarItems } from "../utils/config"
import { FaPrint } from "react-icons/fa6"
import { useAxios } from "../../../utils/axios.config"
import { useParams } from "react-router-dom"
import { Payment } from "../../../utils/@types"
import { useEffect, useState } from "react"
import { formatDate } from "./utils/date"
import { usePDF } from 'react-to-pdf';

const Receipt = () => {

    const params = useParams()
    const {axios} = useAxios()

    const [payment, setPayment] = useState<Payment|null>(null)

    const getPaymnet = () => {
        axios.get(`/admin/payments/${params.id}`).then(({data}: {data: Payment}) => {
            setPayment(data)
        })
    }

    useEffect(()=>{
        getPaymnet()
    },[])

    const { toPDF, targetRef } = usePDF({filename: 'receipt.pdf'});

    const handlePrint = () =>{
        window.print()
    }

    return (
        <LoggedInShell
            header={{
                title: 'Payments',
                subtitle: 'All payments made by clients',
                middle_elements: <HeaderMiddleElements />,
            }}
            sidebar_items={{
                current: 'Payments',
                ...SuperAdmin_SidebarItems,
            }}
        >
            <section className="mt-6 bg-white p-8 rounded-xl">
                {payment && <div id="printable-div" 
                ref={targetRef} 
                className="receipt w-full">
                    <img src="/logo_white_bg.jpg" className="w-[200px]" alt="Logo" />
                    <div className="px-4 mt-4">
                        <p>Hello {payment.user.firstName}, <br /> This is the receipt for a payment of RWF {payment.package.price} for "<b>{payment.package.name}</b>" Package  you made to <b>Abacu Ltd</b></p>

                        <div className="flex items-center justify-between mt-8">
                            <div>
                                <p>TX_REF</p>
                                <p className="font-semibold text-lg">{payment.tx_ref}</p>
                            </div>
                            <div className="text-end">
                                <p>Payment date</p>
                                <p className="font-semibold text-lg">{formatDate(new Date(payment.updatedAt))}</p>
                            </div>
                        </div>

                        <div className="flex items-center justify-between mt-8">
                            <div>
                                <p className="font-semibold text-lg">Company</p>
                                <p>Abacu LTD</p>
                                <p>Kigali, Rwanda </p>
                                <p>CENTENARY BUILDING , 2nd Floor</p>
                                <p>support@abacu.rw</p>
                            </div>
                            <div className="text-end">
                                <p className="font-semibold text-lg">Client</p>
                                <p>N/A</p>
                                <p>N/A</p>
                                <p>{`${payment.user.firstName} ${payment.user.lastName}`}</p>
                                <p>{payment.user.email}</p>
                            </div>
                        </div>
                        <div className="mt-4">
                            <TableContainer>
                                <Table className="" variant='unstyled'>
                                    <Thead className="bg-[#F5F6F7]">
                                        <Tr className="text-[#4A5154]">
                                            <Th className="w-[70%]">Product</Th>
                                            <Th>Reference</Th>
                                            <Th>Unit</Th>
                                            <Th>Amount</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        <Tr className="border-b border-[#E5E7E8]">
                                            <Td>
                                                <div className="flex gap-2">
                                                    <div className="w-[24px] h-[24px] mt-1 bg-gray-200 rounded-full flex items-center justify-center text-[12px]">1</div>
                                                    <div className="">
                                                        <p className="text-sm font-semibold">Service</p>
                                                        <p className="text-sm text-[#7B878C]">Abacu "<b>{payment.package.name}</b>" Package</p>
                                                    </div>
                                                </div>
                                            </Td>
                                            <Td><span className="text-[#4A5154]">{payment.user.firstName}</span></Td>
                                            <Td><span className="text-[#4A5154]">Package</span></Td>
                                            <Td><span className="text-[#4A5154]">RWF {payment.package.price}</span></Td>
                                        </Tr>
                                        <Tr className="border-b border-[#E5E7E8]"></Tr>
                                        <Tr className="border-b border-[#E5E7E8]">
                                            <Td></Td>
                                            <Td></Td>
                                            <Td><span className="text-[#191B1C] font-semibold">Sub-Total</span></Td>
                                            <Td><span className="text-[#4A5154]">RWF {payment.package.price}</span></Td>
                                        </Tr>
                                        <Tr className="border-b border-[#E5E7E8]">
                                            <Td></Td>
                                            <Td></Td>
                                            <Td><span className="text-[#191B1C] font-semibold">Vat Rate</span></Td>
                                            <Td><span className="text-[#4A5154]">18%</span></Td>
                                        </Tr>
                                        <Tr className="border-b border-[#E5E7E8]">
                                            <Td></Td>
                                            <Td></Td>
                                            <Td><span className="text-[#191B1C] font-semibold">Vat Due</span></Td>
                                            <Td><span className="text-[#4A5154]">RWF {payment.package.price*0.18} </span></Td>
                                        </Tr>
                                        <Tr className="">
                                            <Td></Td>
                                            <Td></Td>
                                            <Td><span className="text-[#191B1C] font-semibold">TATAL DUE</span></Td>
                                            <Td><span className="text-[#191B1C] font-semibold">{payment.package.price-(payment.package.price*0.18)} RWF</span></Td>
                                        </Tr>
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </div>
                        <div className="mt-4">
                            <p className="text-center text-sm text-[#191B1C]">Thank you very much for doing business with us. We look forward to working with you again!</p>
                        </div>

                        
                    </div>
                </div>}
                <div className="flex items-center gap-3 mt-8">
                    <button onClick={() => toPDF()} className="h-[40px] px-4 bg-blue-50 text-blue-600 rounded-full hover:bg-blue-100 transition">Download</button>
                    <button onClick={()=>handlePrint()} className="h-[40px] px-4 flex items-center gap-2 bg-blue-500 text-white rounded-full hover:bg-blue-600 transition">
                        <FaPrint />
                        Print Receipt
                    </button>
                </div>
            </section>
        </LoggedInShell>
    )
}

export default Receipt