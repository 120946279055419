export const formatDate = (date: Date): string => {
    
    const formatter = new Intl.DateTimeFormat('en-US', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      });
    const parts = formatter.formatToParts(date);
  
    // Extracting day, month, year, hour, minute
    const day = parts.find(part => part.type === 'day')?.value;
    const month = parts.find(part => part.type === 'month')?.value;
    const year = parts.find(part => part.type === 'year')?.value;
    const hour = parts.find(part => part.type === 'hour')?.value;
    const minute = parts.find(part => part.type === 'minute')?.value;
    const daySuffix = day === '1' || day === '21' || day === '31' ? 'st' : (day === '2' || day === '22' ? 'nd' : (day === '3' || day === '23' ? 'rd' : 'th'));
  
    // Concatenating the formatted date
    return `${day}${daySuffix} ${month}, ${year} - ${hour}:${minute} ${parts.find(part => part.type === 'dayPeriod')?.value}`;
  };