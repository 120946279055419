import React from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  to: string;
}

const Redirect = ({ to }: Props) => {
  const navigate = useNavigate();
  React.useEffect(() => {
    navigate(to);
  }, []);
  return <></>;
};

export default Redirect;
