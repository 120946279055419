import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import { useAuth } from '../../utils/hooks/user.hook';
import { Role } from '../../utils';
import Redirect from '../Redirect';

interface Props {
  children: React.ReactNode;
}

const AdminProtection = ({ children }: Props) => {
  const { user } = useAuth();
  if (!user || user.role !== Role.ADMIN)
    return (
      <Routes>
        <Route path="*" element={<Redirect to="/404" />} />
      </Routes>
    );
  return <Routes>{children}</Routes>;
};

export default AdminProtection;
