import React, { useEffect, useMemo, useState } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { CustomTable } from "./Table";

function sortData(
  data: RowData[],
  payload: { sortBy: keyof RowData | null; reversed: boolean; search?: string }
) {
  const { sortBy, reversed, search } = payload;

  if (search) {
    data = data.filter((item) => {
      const values = Object.values(item);
      return values.some((value) => {
        return JSON.stringify(value)
          .toLowerCase()
          .includes(search.toLowerCase());
      });
    });
  }

  if (!sortBy) {
    return data;
  }

  return [...data].sort((a, b) => {
    return reversed
      ? JSON.stringify(b[sortBy]).localeCompare(JSON.stringify(a[sortBy]))
      : JSON.stringify(a[sortBy]).localeCompare(JSON.stringify(b[sortBy]));
  });
}

export interface RowData<T = any> {
  [key: string]: string | T;
}

export interface RowContext<T = any> {
  /**
   * Whether the row is selected or not
   */
  selected: boolean;
  /**
   * String value of the cell
   */
  value: string;
  /**
   * Full row data
   */
  row: RowData<T>;
  /**
   * All the rows in the table
   */
  rows: RowData<T>[];
}

export interface Column<T = any> {
  /**
   * Header title of the column
   */
  title: string;
  /**
   * key in the row data object
   */
  key: string;
  /**
   * Whether the column can be sorted or not
   */
  sortable?: boolean;
  align?: "left" | "center" | "right";
  /**
   * Custom element to be rendered in the cell
   */
  Element?: (rowContext: RowContext<T>, refresh?: () => void) => React.ReactNode;
}

interface TableWrapperProps<T = any> {
  /**
   * Custom class name for the table wrapper
   */
  className?: string;
  /**
   * Table columns
   */
  columns: Column<T>[];
  /**
   * Array of data to be displayed in the table
   */
  data: RowData<T>[];
  /**
   * Title of the Wrapper
   */
  title: string;
  /**
   * Function that resets the table data to its original state before filtering and sorting and searching ...
   */
  reset?: () => void;
  /**
   * Function to set the data of the table
   */
  setData?: React.Dispatch<React.SetStateAction<RowData<T>[]>>;
  /**
   * A function that returns filters to be displayed on the right side of the search bar
   * @param data Original table data
   * @param sortedData Filtered and sorted data that is being currently displayed in the table
   * @param setData Function to set the data of the table
   * @param reset Function to reset the table to its original state
   * @returns
   */
  filters?: (
    data: RowData[],
    sortedData: RowData[],
    setData?: React.Dispatch<React.SetStateAction<RowData[]>>,
    reset?: () => void
  ) => React.ReactNode[] | React.ReactNode;
  /**
   * Whether the table is filterable by date or not
   * @default false
   */
  filterableByDate?: boolean;
  /**
   * Function to refresh the table data
   */
  refresh?: () => void;
}
const TableWrapper = ({
  columns,
  data,
  className,
  title,
  filters,
  setData,
  reset,
  filterableByDate = false,
  refresh,
}: TableWrapperProps) => {
  const [search, setSearch] = useState<string>("");
  const [selection, setSelection] = useState<string[]>([]);
  const [sortBy, setSortBy] = useState<keyof RowData | null>(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);

  useEffect(() => {
    setPage(1);
    setSortBy(null);
    setReverseSortDirection(false);
  }, [search]);

  const sortedData = useMemo<RowData[]>(() => {
    return sortData(data, { sortBy, reversed: reverseSortDirection, search });
  }, [sortBy, reverseSortDirection, data, search]);

  const onSort = (key: keyof RowData) => {
    if (sortBy === key && reverseSortDirection) {
      setSortBy(null);
      setReverseSortDirection(false);
      return;
    }
    if (sortBy === key) {
      setReverseSortDirection((current) => !current);
    } else {
      setSortBy(key);
      setReverseSortDirection(false);
    }
  };

  return (
    <div
      className={[
        "flex flex-col gap-4 py-8 px-4 rounded-xl bg-white",
        className,
      ].join(" ")}
    >
      {/* HEADER */}
      <div className="flex flex-col flex-wrap lg:flex-row lg:justify-between gap-2 items-center">
        <h1 className="font-bold text-2xl">{title}</h1>
        {/* SEARCH + OTHER FILTERS */}
        <div className="flex flex-row flex-wrap items-center gap-2 h-fit">
          <div className="flex flex-row items-center rounded-md h-full bg-color-a4 px-4 py-2 gap-2">
            <IoSearchOutline className="stroke-[#5D6E8B]" />
            <input
              type="text"
              placeholder={"Search here..."}
              onChange={(e) => setSearch(e.target.value)}
              value={search}
              className="grow border-none outline-none bg-transparent h-full"
              style={{
                boxShadow: "none",
              }}
            />
          </div>
          <div className="flex flex-col md:flex-row gap-2 md:items-center">
            {!!filters && filters(data, sortedData, setData, reset)}
          </div>
          {/* {filterableByDate && (
            <div className="flex flex-row items-center h-full">
              <Popover position="bottom-end" offset={6}>
                <Popover.Target>
                  <Button
                    leftSection={
                      <IoCalendarOutline className="stroke-color-a2 h-4 w-4" />
                    }
                    className="!text-color-a2 !bg-color-a4 !h-full"
                  >
                    Today
                  </Button>
                </Popover.Target>
                <Popover.Dropdown>
                  <DatePicker
                    type="range"
                    value={dateRange}
                    onChange={setDateRange}
                  />
                  ;
                </Popover.Dropdown>
              </Popover>
            </div>
          )} */}
        </div>
      </div>
      <CustomTable
        data={data}
        columns={columns}
        className={className}
        selection={selection}
        setSelection={setSelection}
        sortedData={sortedData}
        sortBy={sortBy}
        reverseSortDirection={reverseSortDirection}
        onSort={onSort}
        page={page}
        setPage={setPage}
        refresh={refresh ?? (() => {})}
      />
    </div>
  );
};

export default TableWrapper;
