export const formatDate = (date: Date, time: boolean = false) => {
  return Intl.DateTimeFormat("en-UK", {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    ...(time && {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    }),
  }).format(date);
};

export enum Role {
  ADMIN = "ADMIN",
  USER = "USER",
  EMPLOYEE = "EMPLOYEE",
}

const roleToLink = {
  [Role.ADMIN]: "/superadmin",
  [Role.USER]: "/author/writings",
  [Role.EMPLOYEE]: "/superadmin",
};

export const profileLink = (role: Role) => {
  const link = roleToLink[role];
  if (!link) return "/";
  return link;
};

export const SearchableLinks = [
  {
    label: "Analytics",
    value: "/superadmin/analytics"
  },
  {
    label: "Users",
    value: "/superadmin/users"
  },
  {
    label: "Writings",
    value: "/superadmin/writings"
  },
  {
    label: "Plans",
    value: "/superadmin/plans"
  }
]

export const formatUnderScoreText = (text: string): string => {
  return text
     .split('_')
     .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
     .join(' ');
};

export default {};
