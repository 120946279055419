import { FC, ReactNode, createContext, useState } from "react";
import { ISupportedLanguages } from "../../utils/@types";

interface LanguagesContextType {
  language: ISupportedLanguages;
  setLanguage: (lang: ISupportedLanguages) => void;
}

const LanguagesContext = createContext<LanguagesContextType | undefined>(
  undefined
);

const LanguagesProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [language, setLanguage] = useState<ISupportedLanguages>("rw");

  const contextValue: LanguagesContextType = {
    language,
    setLanguage,
  };

  return (
    <LanguagesContext.Provider value={contextValue}>
      {children}
    </LanguagesContext.Provider>
  );
};

export { LanguagesProvider, LanguagesContext };
