import { Avatar, Badge } from "@mantine/core";
import { GoDotFill } from "react-icons/go";
import { Column, RowContext } from "../../../../components/tables/TableWrapper";
import { Button } from "@chakra-ui/react";
import { BiPen, BiPencil } from "react-icons/bi";
import { FaEye } from "react-icons/fa6";
import { Link } from "react-router-dom";

type Client = {
  id: number
  names: string
  email: string;
  description: string;
  paidPlan: string;
  paymentMethod: string;
  amount: number;
  status: "PENDING" | "PAID" | "FAILED";
};

export const columns: Column[] = [
  {
    title: "Names",
    key: "names",
    sortable: true,
    
  },
  {
    title: "Email",
    key: "email",
    sortable: true,
    
  },
  {
    title: "Paid Plan",
    key: "plan",
    sortable: true,
    
  },
  {
    title: "Payment Method",
    key: "method",
    sortable: true,
    
  },
  {
    title: "Amount Paid",
    key: "amount",
    sortable: true,
    
  },
  {
    title: "Status",
    key: "status",
    sortable: true,
    Element: ({ row }: RowContext<Client>) => {
      return (
        <div className={`flex items-center justify-center gap-2 rounded-full w-fit px-2 capitalize ${row.status === "PAID"? "bg-green-100 text-green-600": row.status === "PENDING"? "bg-yellow-200 text-yellow-600":"bg-red-200 text-red-600"}`}>
          <div className={`w-[8px] h-[8px] rounded-full ${row.status === "PAID"? "bg-green-600": row.status === "PENDING"? "bg-yellow-600 ":"bg-red-600"}`}></div> {`${String(row.status)?.toLowerCase()}`}
        </div>
      )
    }    
  },
  {
    title: "Actions",
    key: "actions",
    sortable: false,
    Element: ({ row }: RowContext<Client>) => {
      return (
        <div className="">
          <Link to={`receipt/${row.id}`}>
            <Button
              leftIcon={<FaEye />}
              bgColor={"blue.50"}
              textColor={"blue.600"}
            >
              Receipt
            </Button>
          </Link>
        </div>
      )
    }
  }
];
