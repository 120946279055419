import { Autocomplete } from '@mantine/core';
import { CiSearch } from 'react-icons/ci';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { SearchableLinks } from '../../utils';
import { CUIAutoComplete } from 'chakra-ui-autocomplete';

const StyledAutocomplete = styled(Autocomplete)`
& .mantine-Autocomplete-input	{
  border: none;
  padding: 30px 20px;
}

& .mantine-InputWrapper-root {
  margin-top: 0;
  background-color: red;
}
`

const HeaderMiddleElements = () => {
  const navigate = useNavigate();
  const icon = <CiSearch className="h-6 w-6" />;
  return (
    <div className="grow px-4 ml-8">
      <StyledAutocomplete
        mt="md"
        style={{
          input: {
            border: 'none !important'
          }
        }}
        data={SearchableLinks}
        rightSectionPointerEvents="none"
        rightSection={icon}
        placeholder="Type here to search"
        onChange={(value, ...args) => {
          console.log(value, args)
          navigate(`/superadmin/${value.toLowerCase()}`);
        }}
      />
      {/* <CUIAutoComplete
        items={SearchableLinks}
        // rightSectionPointerEvents="none"
        // icon={icon}
        hideToggleButton
        placeholder="Type here to search"
        label=''
        
        // onChange={(value, ...args) => {
        //   console.log(value, args)
          // navigate(value);
        // }}
      /> */}
    </div>
  );
};

export default HeaderMiddleElements;
