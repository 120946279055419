import React, { Suspense } from 'react';
import { useTranslate } from './Translate';
interface Props {
   children: React.ReactNode;
}

const SuspenseWithLoader = ({ children }: Props) => {
   return <Suspense fallback={<SuspenseLoader />}>{children}</Suspense>;
};

export default SuspenseWithLoader;

export const SuspenseLoader = () => {
   const t = useTranslate()
   return (
   
      <div className="flex items-center justify-center h-screen ">
         <div className="animate-pulse flex flex-col  items-center">
            {/* <Logo /> */}
            <div className="flex items-center mt-4 gap-4">
               <h1 className="opacity-60"> {t('Loading')}...</h1>
            </div>
         </div>
      </div>
   )
};