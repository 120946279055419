import { lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Redirect from './components/Redirect';
import ScrollToTop from './components/ScrollToTop';
import SuspenseWithLoader from './components/common/SuspenseWithLoader';
import AdminProtection from './components/route-protection/AdminProtection';
import { LanguagesProvider } from './components/providers/language.provider';
import BillingsPage from './pages/superadmin/payments';
import Receipt from './pages/superadmin/payments/receipt';

const Homepage = lazy(() => import('./pages/Homepage'));
const Login = lazy(() => import('./pages/auth/Login'));
const SignUp = lazy(() => import('./pages/auth/SignUp'));
const ForgotPassword = lazy(() => import('./pages/auth/ForgotPassword'));
const ChangePassword = lazy(() => import('./pages/auth/ChangePassword'));
const NotFound = lazy(() => import('./pages/404'));
const Searchpage = lazy(() => import('./pages/Search'));
const Pricing = lazy(() => import('./pages/Pricing'));
const Checkout = lazy(() => import('./pages/Checkout'));
const Writings = lazy(() => import('./pages/author/Writings'));
const Story = lazy(() => import('./pages/Story'));
const Admin_Analytics = lazy(() => import('./pages/superadmin/Analytics'));
const Admin_Users = lazy(() => import('./pages/superadmin/Users'));
const Admin_Writings = lazy(() => import('./pages/superadmin/Writings'));
const Admin_Plans = lazy(() => import('./pages/superadmin/Plans'));
const Admin_Billings = lazy(() => import('./pages/superadmin/payments'));
const Admin_Feedback = lazy(() => import('./pages/superadmin/feedback'));
const Admin_Logs = lazy(() => import('./pages/superadmin/Logs'));
const Writing = lazy(() => import('./pages/author/Writing'));
const Logout = lazy(() => import('./pages/auth/Logout'));
const Edit = lazy(() => import('./pages/author/Edit'));
const AboutUs = lazy(() => import('./pages/AboutUs'));

const App = () => {
  return (
    <LanguagesProvider>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={
              <SuspenseWithLoader>
                <Homepage />
              </SuspenseWithLoader>
            }
          />
          <Route
            path="/search"
            element={
              <SuspenseWithLoader>
                <Searchpage />
              </SuspenseWithLoader>
            }
          />
          <Route
            path="/pricing"
            element={
              <SuspenseWithLoader>
                <Pricing />
              </SuspenseWithLoader>
            }
          />
          <Route
            path="/checkout"
            element={
              <SuspenseWithLoader>
                <Checkout />
              </SuspenseWithLoader>
            }
          />
          <Route
            path="/about-us"
            element={
              <SuspenseWithLoader>
                <AboutUs />
              </SuspenseWithLoader>
            }
          />
          <Route
            path="/story/:slug"
            element={
              <SuspenseWithLoader>
                <Story />
              </SuspenseWithLoader>
            }
          />

          <Route
            path="/auth/*"
            element={
              <Routes>
                <Route
                  path="login"
                  element={
                    <SuspenseWithLoader>
                      <Login />
                    </SuspenseWithLoader>
                  }
                />
                <Route
                  path="logout"
                  element={
                    <SuspenseWithLoader>
                      <Logout />
                    </SuspenseWithLoader>
                  }
                />
                <Route
                  path="signup"
                  element={
                    <SuspenseWithLoader>
                      <SignUp />
                    </SuspenseWithLoader>
                  }
                />
                <Route
                  path="forgot-password"
                  element={
                    <SuspenseWithLoader>
                      <ForgotPassword />
                    </SuspenseWithLoader>
                  }
                />
                <Route
                  path="change-password"
                  element={
                    <SuspenseWithLoader>
                      <ChangePassword />
                    </SuspenseWithLoader>
                  }
                />
                <Route
                  path="*"
                  element={
                    <SuspenseWithLoader>
                      <NotFound />
                    </SuspenseWithLoader>
                  }
                />
              </Routes>
            }
          />

          <Route
            path="/author/*"
            element={
              <Routes>
                <Route path="" element={<Redirect to="/author/writings" />} />
                <Route
                  path="writings"
                  element={
                    <SuspenseWithLoader>
                      <Writings />
                    </SuspenseWithLoader>
                  }
                />
                <Route
                  path="writing"
                  element={
                    <SuspenseWithLoader>
                      <Writing />
                    </SuspenseWithLoader>
                  }
                />
                <Route
                  path="writings/edit/:storyId"
                  element={
                    <SuspenseWithLoader>
                      <Edit />
                    </SuspenseWithLoader>
                  }
                />
                <Route
                  path="*"
                  element={
                    <SuspenseWithLoader>
                      <NotFound />
                    </SuspenseWithLoader>
                  }
                />
              </Routes>
            }
          />

          <Route
            path="/superadmin/*"
            element={
              <AdminProtection>
                <Route path="" element={<Redirect to="/superadmin/analytics" />} />
                <Route
                  path="analytics"
                  element={
                    <SuspenseWithLoader>
                      <Admin_Analytics />
                    </SuspenseWithLoader>
                  }
                />
                <Route
                  path="users"
                  element={
                    <SuspenseWithLoader>
                      <Admin_Users />
                    </SuspenseWithLoader>
                  }
                />
                <Route
                  path="writings"
                  element={
                    <SuspenseWithLoader>
                      <Admin_Writings />
                    </SuspenseWithLoader>
                  }
                />

                <Route
                  path="payments"
                  element={
                    <SuspenseWithLoader>
                      <BillingsPage />
                    </SuspenseWithLoader>
                  }
                />
                
                <Route
                  path="payments/receipt/:id"
                  element={
                    <SuspenseWithLoader>
                      <Receipt />
                    </SuspenseWithLoader>
                  }
                />

                <Route
                  path="plans"
                  element={
                    <SuspenseWithLoader>
                      <Admin_Plans />
                    </SuspenseWithLoader>
                  }
                />
                <Route
                  path="feedback"
                  element={
                    <SuspenseWithLoader>
                      <Admin_Feedback />
                    </SuspenseWithLoader>
                  }
                />
                <Route
                  path="logs"
                  element={
                    <SuspenseWithLoader>
                      <Admin_Logs />
                    </SuspenseWithLoader>
                  }
                />
                <Route
                  path="*"
                  element={
                    <SuspenseWithLoader>
                      <NotFound />
                    </SuspenseWithLoader>
                  }
                />
              </AdminProtection>
            }
          />

          <Route
            path="*"
            element={
              <SuspenseWithLoader>
                <NotFound />
              </SuspenseWithLoader>
            }
          />
        </Routes>
      </BrowserRouter>
    </LanguagesProvider>
  );
};

export default App;
