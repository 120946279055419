import { useEffect, useState } from "react"
import HeaderMiddleElements from "../../../components/common/HeaderMiddleElements"
import LoggedInShell from "../../../components/layout/LoggedInShell"
import { SuperAdmin_SidebarItems } from "../utils/config"
import { IoSearchOutline } from "react-icons/io5"
import { Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react"
import { useAxios } from "../../../utils/axios.config"
import { Payment } from "../../../utils/@types"
import PaymentLine from "../../../components/superadmin/billing/PaymentLine"
import TableWrapper, { RowData } from "../../../components/tables/TableWrapper"
import { columns } from "./utils/config"
import StyledTabs from "../../../components/Tabs.styled"

const BillingsPage = () => {

    const tableFilters = (
        _data: RowData[],
        _sortedData: RowData[],
        _setData?: React.Dispatch<React.SetStateAction<RowData[]>>,
        reset?: () => void
      ) => {
    
        return (
          <StyledTabs className="bg-color-a4 rounded-md" defaultValue={"all"}>
            <></>
          </StyledTabs>
        );
      };
    

    const [search, setSearch] = useState('')
    const [payments, setPayments] = useState<any[]>([])

    const { axios } = useAxios()

    const getPayments = () => {

        axios.get('/admin/payments').then(({ data }: { data: Payment[] }) => {
            const _payments = data.map((el: Payment) => {
                return (
                    {
                       id: el.id,
                       names: `${el.user.firstName} ${el.user.lastName}`,
                       email: el.user.email,
                       plan: el.package.name,
                       method: "FLUTTERWAVE",
                       amount: el.package.price,
                       status: el.status,
                    }
                )
            })

            setPayments(_payments)
        })
    }

    useEffect(() => {
        getPayments()
    }, [])

    return (
        <LoggedInShell
            header={{
                title: 'Payments',
                subtitle: 'All payments made by clients',
                middle_elements: <HeaderMiddleElements />,
            }}
            sidebar_items={{
                current: 'Payments',
                ...SuperAdmin_SidebarItems,
            }}
        >
            <section className="mt-6">
                <TableWrapper title={'Payments'} columns={columns} data={payments} filterableByDate filters={tableFilters} />
            </section>
        </LoggedInShell>
    )
}

export default BillingsPage