import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';
import AxiosClient from 'axios';

export const useAxios = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const axios = AxiosClient.create({
    baseURL: process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_PROD_API_URL,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response) {
        if (error.response.data) {
          toast({
            title: error.response.data.error ?? 'Something went wrong',
            description: error.response.data.message ?? 'Please try again later.',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
        }
        if (error.response.status === 401) {
          toast({
            title: 'Authentication Error',
            description: 'redirecting to login page...',
            status: 'info',
            duration: 400,
          });
          setTimeout(() => {
            navigate('/auth/login');
          }, 400);
        }
      }
      return Promise.reject(error);
    },
  );
  return {
    axios,
  };
};

export const APP_URL = process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_FE_DEV_URL : process.env.REACT_APP_FE_PROD_URL;
